import { useEffect, useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import ImageModal from '../ImageModal';
import ImageCard from '../molecules/ImageCard';

import { BlogPost, blogPosts } from '../../constants/blogs';

function BlogPostCarousel() {
  const [isOpen, setIsOpen] = useState(false);
  const [thePost, setThePost] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
      setIsMediumScreen(window.innerWidth > 1200 && window.innerWidth < 1600);
    };

    handleResize(); // Set initial value on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const itemsPerPage = (() => {
    if (isSmallScreen) return 1;
    if (isMediumScreen) return 2;
    return 2;
  })();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex + itemsPerPage;
      if (nextIndex >= blogPosts.length) {
        return 0; // Wrap to the beginning
      }

      if (nextIndex + itemsPerPage > blogPosts.length) {
        return blogPosts.length - (blogPosts.length % itemsPerPage);
      }

      return nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const lastSetIndex =
      blogPosts.length - (blogPosts.length % itemsPerPage || itemsPerPage);

      if (prevIndex === 0) {
        return lastSetIndex;
      }

      return Math.max(0, prevIndex - itemsPerPage);
    });
  };

  const visibleItems = blogPosts.slice(
    currentIndex,
    currentIndex + itemsPerPage,
  );

  const handleArticleClick = (post: BlogPost) => {
    setIsOpen(true);
    setThePost(post);
  };

  return (
    <div className="flex items-center gap-4">
      <button
        className="rounded-full bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 md:p-4"
        title="previous"
        onClick={prevSlide}
      >
        <ArrowLeftIcon className="h-8 w-8" />
      </button>
      {visibleItems.map((post, index) => (
        <article
          key={`${index}-${post.title}`}
          aria-label="Open Image"
          className="relative z-10 flex flex-1 flex-col justify-end overflow-hidden rounded-2xl px-4 pt-80 pb-8 sm:h-[500px] sm:pt-48 lg:pt-80"
          tabIndex={0}
          onClick={() => {
            handleArticleClick(post);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleArticleClick(post);
            }
          }}
        >
          <ImageCard
            image={{
              title: post.title,
              imgSrc: process.env.NEXT_PUBLIC_GOOGLE_CDN + post.postImage,
              caption: post.headline,
              date: post.date,
            }}
          />
        </article>
      ))}
      <button
        className="rounded-full bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 md:p-4"
        title="next"
        onClick={nextSlide}
      >
        <ArrowRightIcon className="h-8 w-8" />
      </button>

      <ImageModal
        isOpen={isOpen}
        post={thePost as BlogPost}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}

export default BlogPostCarousel;
